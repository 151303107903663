import React from 'react'

import 'twin.macro'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { Text as ContentfulText } from '@contentful/rich-text-types/dist/types/types'
import { motion } from 'framer-motion'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'

import { SubmitMailing } from 'mx/locations/submit-mailing'
import { Section } from 'mx/section'
import { FormContactSource } from 'types/crm'

const variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { ease: 'easeOut', delay: 0.25, duration: 0.75 },
  },
  hidden: { opacity: 0, y: 75 },
}

interface SignUpHeroProps {
  contactSource?: FormContactSource
  data: {
    contentSlots: {
      actionText: string
      markdown: RenderRichTextData<ContentfulRichTextGatsbyReference>
      photos?: {
        title: string
        localFile: {
          childImageSharp: {
            gatsbyImageData: IGatsbyImageData
          }
        }
        file: {
          url: string
        }
      }[]
      slotTitle: string
    }[]
  }
  guideName?: string
  resourceUrl?: string
}
const SignUpHero = ({
  contactSource,
  data,
  guideName,
  resourceUrl,
}: SignUpHeroProps) => {
  const content = data.contentSlots[0]
  return (
    <Section tw="mt-16 md:mt-32" padding="none">
      <motion.div initial="hidden" animate="visible" variants={variants}>
        <div tw="w-full flex flex-col-reverse md:flex-row">
          <div tw="w-full md:w-7/12">
            <div tw="px-4 flex flex-col justify-center relative md:pl-6">
              <div tw="flex w-full mb-8 flex-row md:flex-col">
                <h1
                  tw="max-w-lg font-thin text-gray-800 leading-tight text-4xl my-auto"
                  dangerouslySetInnerHTML={{
                    __html: content.slotTitle ?? '',
                  }}
                />
              </div>
              <div tw="flex flex-col justify-start items-start self-start space-y-8">
                {content.markdown &&
                  renderRichText(content.markdown, {
                    renderNode: {
                      [BLOCKS.PARAGRAPH]: (node, children) => {
                        const { value: textValue } = node
                          .content[0] as ContentfulText
                        if (node.nodeType === 'paragraph' && textValue) {
                          return (
                            <p tw="mx-auto leading-loose font-light text-gray-600 max-w-xl text-base md:text-lg">
                              {children}
                            </p>
                          )
                        }
                      },
                      [INLINES.HYPERLINK]: ({ data: { uri } }, node) => (
                        <a className="link" onClick={() => window.open(uri)}>
                          {node}
                        </a>
                      ),
                    },
                    renderText: (text) =>
                      text
                        .split('\n')
                        .flatMap((text, i) => [i > 0 && <br key={i} />, text]),
                  })}
              </div>
              {content?.actionText && (
                <div tw="mt-8 w-full lg:w-2/3">
                  <p tw="font-light text-gray-500 leading-normal mb-4 max-w-xs md:max-w-md">
                    Please provide your email *
                  </p>
                  <SubmitMailing
                    buttonText={content.actionText}
                    contactSource={contactSource}
                    guideName={guideName}
                    placeholder={'you@example.com'}
                    resourceUrl={resourceUrl}
                  />
                </div>
              )}
            </div>
          </div>
          <div tw="w-full mb-20 md:mb-0 md:w-5/12">
            <div tw="px-4">
              {content.photos?.[0].localFile?.childImageSharp
                ?.gatsbyImageData ? (
                <GatsbyImage
                  alt={content.photos?.[0].title}
                  image={
                    content.photos?.[0].localFile.childImageSharp
                      .gatsbyImageData
                  }
                />
              ) : content.photos?.[0].file.url ? (
                <img
                  src={content.photos?.[0].file.url}
                  alt={content.photos?.[0].title}
                />
              ) : null}
            </div>
          </div>
        </div>
      </motion.div>
    </Section>
  )
}

export default SignUpHero
